<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Users
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        v-model="filter.query"
        placeholder="Zoek..."
        append-icon="fas fa-search"
        single-line
        hide-details
        @keyup="searchKeyUp()"
      ></v-text-field>
      <v-btn
        class="ml-4"
        depressed
        color="secondary"
        @click="newClick"
        :disabled="!user.can('user.create')"
      >
    <v-icon
      small
      left
    >fas fa-folder-plus</v-icon>
      New
    </v-btn>
    </v-row>

    <v-data-table
      :class="{ 'clickable': user.can('user.update') }"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
      :loading="isSearching"
      @click:row="rowClick"
    >
    
      <template v-slot:item.roles="{ item }" v-if="roles.length">
        {{ item.roles.map(roleName => roles.find(role => role.name === roleName).description).join(', ') }}
      </template>
    
      <template v-slot:item.status="{ item }">
        <v-icon :color="item.status == 10 ? 'secondary' : 'grey'" x-small>fas fa-circle</v-icon>
      </template>
    
    </v-data-table>


    <v-dialog
      v-model="dialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="primary" dark flat short class="px-2">
          <v-toolbar-title>User</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-form @submit.prevent>
            <v-text-field
              outlined
              class="mb-2"
              v-model="model.username"
              :error-messages="errors.username"
              label="Username"
            ></v-text-field>

            <v-text-field
              outlined
              class="mb-2"
              v-model="model.email"
              :error-messages="errors.email"
              label="Email"
            ></v-text-field>

            <v-text-field
              outlined
              class="mb-2"
              v-model="model.first_name"
              :error-messages="errors.first_name"
              label="First name"
            ></v-text-field>

            <v-text-field
              outlined
              class="mb-2"
              type="password"
              v-model="model.password"
              :error-messages="errors.password"
              label="New password"
            ></v-text-field>

            <v-checkbox
              v-model="model.status"
              :error-messages="errors.status"
              label="Active"
              :false-value="9"
              :true-value="10"
            ></v-checkbox>


            <v-banner
              single-line
            >Roles</v-banner>

            <v-checkbox
              v-for="role in roles"
              :key="role.name"
              v-model="model.roles"
              :label="role.description"
              :value="role.name"
            ></v-checkbox>

            <v-input
              :error-messages="errors.roles"
            ></v-input>



          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="dialog = false"
          >Cancel</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="saveClick()"
          >Save</v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref } from 'vue'

export default {
  setup() {
    const api = inject('api')
    const loader = inject('loader')
    const { user } = api

    const { items: roles } = api.collection('users/roles').all()

    const userCollection = api.collection('users', {
      params: {
        expand: 'roles,permissions',
      },
      filter: {
        query: 'query:first_name,email',
      },
      store: true,
    })

    const { items, filter, totalCount, options, isSearching } = userCollection

    const headers = [
      { text: 'Username', value: 'username' },
      { text: 'First name', value: 'first_name' },
      { text: 'Email', value: 'email' },
      { text: 'Role', value: 'roles' },
      { text: 'Status', value: 'status', align: 'center' },
    ]

    const dialog = ref(false)

    const userModel = api.model('users/:id', {
      params: {
        expand: 'roles,permissions',
      },
    })
    const { attributes: model, errors, isBusy } = userModel

    loader.bindRef(isBusy)

    function newClick() {
      userModel.clear().setAttributes({ status: 9 })
      dialog.value = true
    }

    function rowClick(item) {
      if (user.can('user.update')) {
        userModel.find(item.id).then(() => {
          dialog.value = true
        })
      }
    }

    function saveClick() {
      userModel.save().then(() => {
        userCollection.search()
        dialog.value = false
      })
    }

    const searchKeyUp = userCollection.debounceSearch(300)

    return {
      user,
      headers,
      items,
      filter,
      searchKeyUp,
      totalCount,
      options,
      isSearching,
      newClick,
      rowClick,
      saveClick,
      dialog,
      model,
      errors,
      roles,
    }

  },
}
</script>


<style scoped>

:deep(.v-data-table.clickable table tbody tr:not(.v-data-table__empty-wrapper):hover) {
  cursor: pointer;
}

</style>
